import React, { useState } from 'react';
import SharedLayout from './SharedLayout';
import SectionHeading from './SectionHeading';
import Gallery from './Gallery';

const MembershipPage = () => {
  const [activeTab, setActiveTab] = useState('edinburg');

  const parkContent = {
    edinburg: {
      facilities: [
        "Facilities information for Edinburg park will be coming soon."
      ],
      image: "/img/BPP TX.png"
    },
    fortsmith: {
      facilities: ["Facilities information for Fort Smith park will be coming soon."],
      image: "/img/BPP AR.png"
    },
    hyderabad: {
      facilities: ["Facilities information for Hyderabad park will be coming soon."],
      image: "/img/BPP India.png"
    }
  };

  return (
    <SharedLayout>
      {/* Hero Section */}
      <section className="bg-cover bg-center h-[35vh]" style={{backgroundImage: 'url("/img/membership-hero.png")'}}>
        <div className="h-full flex items-center justify-center text-white">
          <div className="text-center">
            <h1 className="text-[3rem] font-bold mb-4" style={{ 
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' 
                }}>Membership</h1>
          </div>
        </div>
      </section>

      <SectionHeading title="Join the Community" />

      {/* Content Section */}
      <section className="py-16 px-6 sm:px-8 md:px-16 lg:px-24">
        <div className="max-w-4xl mx-auto">
          <p className="text-lg text-center mb-12">
            Join our community and be part of creating joyful, nurturing spaces for children and families!
            Members can participate in a variety of activities, from mentoring programs to community events,
            all aimed at fostering growth and connection. Choose from the following membership options:
          </p>

          {/* Membership Options */}
          <div className="space-y-12 mb-16">
            <div className="text-center">
              <h3 className="text-2xl font-bold mb-4">Adult Membership: $51*/lifetime membership</h3>
              <p className="text-lg mb-2">Engage in meaningful activities and events at our parks.</p>
              <p className="text-lg">Mentor children and contribute to our educational programs.</p>
            </div>

            <div className="text-center">
              <h3 className="text-2xl font-bold mb-4">Volunteer: $1/year</h3>
              <p className="text-lg mb-2">For those under 18 (with parental sign-off).</p>
              <p className="text-lg">Participate in volunteer activities and build valuable skills.</p>
            </div>
          </div>

          {/* Ready to Join Section */}
          <div className="text-center mb-16">
            <h2 className="text-2xl font-bold mb-4">Ready to join?</h2>
                      {/* Tax Exemption Note */}
          <p className="text-sm text-gray-600 text-center mb-5">
            (BPP is a registered nonprofit 501c3 and a tax exemption certificate will be emailed to you.)
          </p>
            <p className="text-lg mb-8">Click below to sign in and complete the registration.</p>
            <div className="flex justify-center gap-8">
              {/* <button className="bg-[#EFBC40] text-black font-bold py-3 px-8 rounded-full hover:bg-opacity-90 transition duration-300">
                Member
              </button> */}
              <button className="bg-[#EFBC40] text-black font-bold py-3 px-8 rounded-full hover:bg-opacity-90 transition duration-300">
                Coming Soon
              </button>
            </div>
          </div>


 {/* Our Parks Section with responsive sizing */}
 <div className="mt-16">
        <SectionHeading title="Our Parks" topSpacing="pt-0" bottomSpacing="mb-10" />
        
        {/* Tabs */}
        <div className="flex justify-center gap-4 mb-8">
          {[
            { id: 'edinburg', label: 'Edinburg, TX' },
            { id: 'fortsmith', label: 'Fort Smith, AR' },
            { id: 'hyderabad', label: 'Hyd, India' }
          ].map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`px-6 py-2 rounded-lg transition duration-300 ${
                activeTab === tab.id
                  ? 'bg-gray-200 font-bold'
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
            >
              {tab.label}
            </button>
          ))}
        </div>

        {/* Tab Content with responsive container */}
        <div className="flex justify-center">
          <div className="relative w-full md:w-2/3 lg:w-3/4">
            <div className="bg-gradient-to-r from-yellow-400 to-pink-500 p-0.5 rounded-lg">
              <div className="bg-white p-8 rounded-lg">
                <h3 className="text-2xl font-bold mb-6 text-center">
                  {activeTab === 'edinburg' ? 'Edinburg, TX' : 
                   activeTab === 'fortsmith' ? 'Fort Smith, AR' : 
                   'Hyderabad, India'}
                </h3>
                {/* Image container */}
                <div className="relative w-full aspect-square mb-8 bg-white rounded-lg overflow-hidden">
                  <img
                    src={parkContent[activeTab].image}
                    alt={`${activeTab} Park`}
                    className="absolute w-full h-full object-contain"
                  />
                </div>
                <div className="space-y-2">
                  {parkContent[activeTab].facilities.map((facility, index) => (
                    <p key={index} className="text-lg"></p>//{facility}
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Gallery Section */}
      <Gallery />



        </div>
      </section>
    </SharedLayout>
  );
};

export default MembershipPage;