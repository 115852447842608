import React from 'react';
import SharedLayout from './SharedLayout';

const TermsConditionsPage = () => {
  return (
    <SharedLayout>
      {/* Hero Section */}
      <section className="bg-cover bg-center h-[35vh]" style={{backgroundImage: 'url("/img/homepage header.png")'}}>
        <div className="h-full flex items-center justify-center text-white">
          <div className="text-center">
            <h1 className="text-[3rem] font-bold mb-4" style={{ 
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' 
                }}>Terms & Conditions</h1>
          </div>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-16 px-6 sm:px-8 md:px-16 lg:px-24">
        <div className="max-w-4xl mx-auto">
          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-4">Definition of Bloom Parks</h2>
            <p className="text-lg mb-8">
              For the purposes of this agreement, "Bloom Parks" refers to all properties, spaces, and facilities under the management of Bloom Parks and Projects (BPP) & SKTWS, including all associated locations where activities, events, or rentals take place.
            </p>

            <h2 className="text-2xl font-bold mb-4">Code of Conduct & Liability Release Public Declaration</h2>
            <p className="text-lg mb-6">
              As a visitor, member, donor, volunteer, or client using or renting BPP & SKTWS properties or attending any events organized at these locations, I agree to the following:
            </p>

            <h3 className="text-xl font-bold mb-3">1. Responsibility for Safety</h3>
            <p className="text-lg mb-8">
              I take full responsibility for the safety of myself and my companions, including my children or dependents, during any activities or events at Bloom Parks. This includes participation in any games, boating, or water sports, where no lifeguard is on duty.
            </p>

            <h3 className="text-xl font-bold mb-3">2. Release from Liability</h3>
            <p className="text-lg mb-8">
              I release Bloom Parks and Projects (BPP), TX, USA & SKTWS, Hyderabad, India, including Dr. Amit Taggarse (Founder-Director, President), Ms. Rati Tripathi (Director), and all other directors, officers, members, and volunteers from any liability for accidental injury, harm, or death that may occur during my participation in any activities now or in the future.
            </p>

            <h3 className="text-xl font-bold mb-3">3. Assumption of Risk</h3>
            <p className="text-lg mb-8">
              I understand that activities like swimming, fishing, boating, or interacting with animals involve risks, including physical injury or harm. I voluntarily assume all risks associated with these activities for myself and my companions.
            </p>

            <h3 className="text-xl font-bold mb-3">4. Code of Conduct</h3>
            <p className="text-lg mb-4">I pledge to:</p>
            <ul className="list-disc pl-6 mb-8 text-lg space-y-2">
              <li>Treat others, including children, senior citizens, animals, birds, and plants, with kindness and respect.</li>
              <li>Not photograph or video-record anyone, or share their images on social media, without their consent.</li>
              <li>Avoid any hurtful speech, inappropriate physical contact, or reckless activities that could endanger others or myself.</li>
              <li>Refrain from bringing or consuming recreational drugs or illegal weapons.</li>
              <li>Not discriminate against anyone based on nationality, race, religion, caste, disability, or financial background.</li>
            </ul>

            <h3 className="text-xl font-bold mb-3">5. Safety Notices and Compliance</h3>
            <p className="text-lg mb-4">
              I acknowledge that I am responsible for following all posted signs and warnings throughout Bloom Parks, including but not limited to areas like water activities, petting zoos, or playgrounds. I understand that signs may include rules regarding animal interactions, handwashing, safety precautions, and behavior requirements.
            </p>
            <p className="text-lg mb-8">
              I agree to follow these rules and accept full responsibility for any risks associated with failure to comply with posted signs. Bloom Parks reserves the right to refuse entry or cancel participation if these rules are violated.
            </p>

            <h3 className="text-xl font-bold mb-3">6. Refusal of Entry and Membership Cancellation</h3>
            <ul className="list-disc pl-6 mb-8 text-lg space-y-2">
              <li>Bloom Parks reserves the right to refuse entry to any individual at its discretion, provided the decision is based on behavior, safety concerns, or violations of park policies. Refusal of entry will not be based on race, religion, gender, national origin, disability, or any other protected class.</li>
              <li>Bloom Parks reserves the right to cancel memberships or volunteer agreements, including "lifetime memberships," without any obligation to refund or compensate the individual, if the individual engages in behavior that violates park rules, safety regulations, or policies.</li>
            </ul>

            <h3 className="text-xl font-bold mb-3">7. Legal Consequences for Reckless Actions</h3>
            <p className="text-lg mb-8">
              I understand that any reckless behavior will result in immediate expulsion from Bloom Parks and may carry legal consequences.
            </p>

            <h3 className="text-xl font-bold mb-3">8. Emergency Medical Treatment</h3>
            <p className="text-lg mb-8">
              In case of a medical emergency, I authorize Bloom Parks staff or volunteers to seek appropriate medical help, and I agree to bear the costs of any treatment.
            </p>

            <h3 className="text-xl font-bold mb-3">9. Indemnification & Limited Right to Lawsuits</h3>
            <p className="text-lg mb-4">
              I agree to indemnify, defend, and hold harmless Bloom Parks and Projects (BPP), SKTWS, and their directors, officers, members, volunteers, and staff from any claims, damages, or losses, including legal fees, arising from:
            </p>
            <ul className="list-disc pl-6 mb-4 text-lg space-y-2">
              <li>Personal Injury or Harm: Accidental injuries or harm sustained during activities, including those caused by natural disasters (weather, wind, heat), or interactions with wild or domesticated animals, birds, insects, or reptiles.</li>
              <li>Financial Information: Loss, theft, or hacking of my personal financial data used in connection with donations, membership fees, or payments, provided that Bloom Parks took reasonable precautions to secure such information.</li>
              <li>Event Cancellations: Any financial loss due to the cancellation of events caused by unforeseen circumstances, such as weather or fire.</li>
            </ul>
            <p className="text-lg mb-8">
              I waive my right to file lawsuits or claims against Bloom Parks for the above reasons except in cases of gross negligence or willful misconduct.
            </p>

            <h3 className="text-xl font-bold mb-3">10. Arbitration Clause</h3>
            <p className="text-lg mb-4">
              In the event of any dispute or claim arising out of or related to my participation in activities or events at Bloom Parks and Projects, I agree to resolve the matter through binding arbitration, which will be governed by the following terms:
            </p>
            <ul className="list-disc pl-6 mb-8 text-lg space-y-2">
              <li>Either Bloom Parks or I may elect to resolve the dispute through arbitration at any point during the dispute or proceeding.</li>
              <li>Arbitration will be conducted in accordance with the rules of the American Arbitration Association (AAA) or a mutually agreed arbitration organization in Arkansas.</li>
              <li>Arbitration will be non-appearance-based, which means the arbitration may be conducted remotely through written submissions, telecommunication, or other agreed methods.</li>
              <li>The decision of the arbitrator will be final and binding. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction in Fort Smith, Arkansas.</li>
              <li>By agreeing to arbitration, I waive my right to a jury trial or to file a lawsuit in court, except to enforce the arbitration decision.</li>
              <li>Gross Negligence or Willful Misconduct: This arbitration agreement will not limit my ability to pursue claims related to gross negligence or willful misconduct, as permitted by Arkansas law.</li>
            </ul>

            <h3 className="text-xl font-bold mb-3">11. Donations and Membership Fees</h3>
            <p className="text-lg mb-8">
              Any money I contribute towards membership or donations is given without expecting anything in return, including refunds, acknowledgments, or praise. I understand that I can request a tax-exemption certificate for my donations.
            </p>

            <h3 className="text-xl font-bold mb-3">12. For Volunteers Under 18</h3>
            <ul className="list-disc pl-6 mb-4 text-lg space-y-2">
              <li>Parental Supervision: Volunteers under the age of 18 must be accompanied and supervised by a parent or legal guardian at all times while volunteering at Bloom Parks. The parent or guardian is fully responsible for ensuring the child adheres to the park's rules and policies.</li>
              <li>Parental Liability Waiver: A parent or legal guardian must sign this Liability Release Agreement on behalf of the volunteer. The signature is required to be accompanied by a $1 payment, which ensures formal consent for the minor's participation and acknowledgment of the risks involved.</li>
            </ul>

            <h4 className="text-lg font-bold mb-3">Data Collection and Privacy (COPPA Compliance):</h4>
            <ul className="list-disc pl-6 mb-8 text-lg space-y-2">
              <li>If Bloom Parks collects any personal information online from children under the age of 13, we will comply with COPPA regulations. This includes obtaining verifiable parental consent before collecting, using, or disclosing any personal information. We will provide parents with the option to review their child's information and request deletion as required by law.</li>
              <li>No personal information will be collected, stored, or shared from volunteers under 13 without express consent from the parent or legal guardian.</li>
            </ul>

            {/* <div className="border-t-2 border-gray-200 pt-8 mb-8">
              <h3 className="text-xl font-bold mb-4">Parental/Guardian Consent for Volunteers:</h3>
              <p className="text-lg italic mb-4">
                If the participant is under 18, the following section must be completed by a parent or guardian.
              </p>
              <p className="text-lg mb-8">
                "As the parent or legal guardian of the volunteer, I consent to the above Liability Release Statement on their behalf and accept full responsibility for their safety, supervision, and compliance with park rules during volunteer activities. I understand that a $1 payment is required to formalize this consent."
              </p>

              <div className="space-y-4 mb-8">
                <p className="text-lg">Name of participant (under 18 years): _______________________</p>
                <p className="text-lg">Parent/Guardian Name: _______________________</p>
                <p className="text-lg">Signature: _______________________ Date: _______________________</p>
              </div>
            </div> */}

            {/* <div className="border-t-2 border-gray-200 pt-8">
              <h3 className="text-xl font-bold mb-4">Signature for All Participants:</h3>
              <div className="space-y-4 mb-8">
                <p className="text-lg">Signature: _______________________ Date: _______________________</p>
              </div>
              
              <div className="flex items-center space-x-4 mt-8">
                <input type="checkbox" id="agreement" className="w-5 h-5 rounded border-gray-300" />
                <label htmlFor="agreement" className="text-lg">
                  By checking this box, I confirm that I have read, understood, and agree to the terms outlined in the Liability Release Statement.
                </label>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </SharedLayout>
  );
};

export default TermsConditionsPage;