import React from 'react';

const DonationSection = ({ title, children }) => {
  return (
    <div className="relative p-0.5 rounded-2xl bg-gradient-to-r from-yellow-400 to-pink-500">
      <div className="bg-white p-6 rounded-2xl">
        <h3 className="text-2xl font-bold mb-6 text-center">{title}</h3>
        {children}
      </div>
    </div>
  );
};

const CheckDonationSection = () => (
  <DonationSection title="BY CHECK:">
    <div className="text-center">
      <p className="text-lg mb-4">Payable to "Bloom Parks & Projects", mailed to our Arkansas office:</p>
      <div className="space-y-1">
        <p className="text-lg font-bold">Bloom Parks & Projects</p>
        <p className="text-lg">C/O Dr. Amit Taggarse</p>
        <p className="text-lg">8612 S 31st Terrace</p>
        <p className="text-lg">Fort Smith, Arkansas 72908</p>
      </div>
    </div>
  </DonationSection>
);

const InKindDonationSection = () => (
  <DonationSection title="IN-KIND DONATIONS:">
    <p className="text-lg text-center">
      To support us with in-kind donations, please reach out directly to receive a current list of 
      our most needed items. Your contributions of goods and supplies are invaluable in helping 
      us serve our community effectively.
    </p>
  </DonationSection>
);

const OtherDonationSections = () => {
  return (
    <div className="space-y-8">
      <CheckDonationSection />
      <InKindDonationSection />
    </div>
  );
};

export default OtherDonationSections;