import React from 'react';

const EventCard = ({ title, date, location, details, imageSrc, imagePosition, eventbriteLink }) => {
  const [address, time] = details.split(/\.(?=[^.]+$)/);

  return (
    <div className="mb-12 mx-4 md:mx-0">
      <div className="relative rounded-lg overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-yellow-400 to-pink-500"></div>
        <div className="relative bg-white m-0.5 rounded-lg flex flex-col md:flex-row overflow-hidden">
          {imagePosition === 'left' && (
            <div className="w-full md:w-1/2">
              <img src={imageSrc} alt={title} className="w-full h-80 object-cover" />
            </div>
          )}
          <div className={`flex-1 flex flex-col justify-center p-6 ${imagePosition === 'right' ? 'md:pr-6' : 'md:pl-6'} text-center`}>
            <h3 className="text-3xl font-bold mb-2">{title}</h3>
            <p className="text-xl font-semibold mb-2">{date}</p>
            <p className="text-lg mb-2">{location}</p>
            <p className="text-gray-600 mb-2">{address}</p>
            <p className="text-gray-600 mb-4">{time}</p>
            <a 
              href={eventbriteLink}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gradient-to-r from-yellow-400 to-pink-500 text-white font-bold py-2 px-6 rounded-full hover:opacity-90 transition duration-300 self-center"
            >
              See Details
            </a>
          </div>
          {imagePosition === 'right' && (
            <div className="w-full md:w-1/2">
              <img src={imageSrc} alt={title} className="w-full h-80 object-cover" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EventsSection = () => {
  const events = [
    {
      title: "Thanksgiving Bloom Festival",
      date: "Nov 9",
      location: "Location: Bloom Park Edinburg",
      details: "10220 N., Big Five Road Edinburg TX 78541, USA. Sat, Nov 9th, 3-8pm",
      imageSrc: "/img/thanksgiving.webp",
      eventbriteLink: "https://www.eventbrite.com/e/1049135431317?aff=oddtdtcreator"
    },
    {
      title: "Christmas Bloom Festival",
      date: "Dec 7",
      location: "Location: Bloom Park Fort Smith",
      details: "12200 Glass Plant Road Fort Smith, AR 72908, USA. Sat, Dec 7th, 3-8pm",
      imageSrc: "/img/xmas.webp",
      eventbriteLink: "https://www.eventbrite.com/e/1049266362937?aff=oddtdtcreator"
    },
    {
      title: "Inauguration & Christmas Festival",
      date: "Dec 15",
      location: "Location: SKTWS-Bloom Park India",
      details: "S No 248/139 Bandamadaram Village, RR District, Hyderabad, INDIA. Sun, Dec 15th, 11am-7pm",
      imageSrc: "/img/xmasIndia.webp",
      eventbriteLink: "https://www.eventbrite.com/e/1049271237517?aff=oddtdtcreator"
    }
  ];

  return (
    <section className="bg-gray-100 py-16 px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold mb-8 text-center">Events</h2>
        <div className="gradient-line w-40 h-1 mx-auto mb-12 bg-gradient-to-r from-yellow-400 to-pink-500"></div>
        {events.map((event, index) => (
          <EventCard
            key={index}
            {...event}
            imagePosition={index % 2 === 0 ? 'left' : 'right'}
          />
        ))}
      </div>
    </section>
  );
};

export default EventsSection;