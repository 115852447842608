import React, { useState, useRef, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <button onClick={toggleMenu} className="menu-button">
        <Menu size={48} color="white" />
      </button>
      <div className={`menu-drawer ${isMenuOpen ? 'open' : ''}`} ref={menuRef}>
        <button onClick={toggleMenu} className="absolute top-4 right-4">
          <X size={36} color="black" />
        </button>
        <nav className="text-center mt-16">
          <ul className="space-y-6">
            {['Home', 'About', 'Membership', 'Donate', 'ATHMED'].map((item) => (
              <li key={item}>
                <Link
                  to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
                  className="text-2xl font-bold block py-2"
                  onClick={toggleMenu}
                >
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Navbar;