import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

const SharedLayout = ({ children, showFooter = true }) => {
  return (
    <div className="min-h-screen flex flex-col bg-white text-black font-sans">
      <div className="absolute top-6 left-6 z-10">
        <Link to="/">
          <img 
            src="/img/logo.png" 
            alt="Bloom Parks and Projects Logo" 
            className="w-24 h-24 sm:w-32 sm:h-32 cursor-pointer transition-transform duration-300 hover:scale-105"
          />
        </Link>
      </div>
      <Navbar />
      <main className="flex-grow">
        {children}
      </main>
      {showFooter && <Footer />}
    </div>
  );
};

export default SharedLayout;