import React from 'react';

const OnlineDonationButtons = () => {
  const donationOptions = [
    {
      name: "Mightycause",
      url: "https://www.mightycause.com/organization/Bloom-Parks-And-Projects",
    },
    {
      name: "myregistry.com",
      url: "https://www.myregistry.com/organization/bloom-parks-and-projects-mcallen-tx/3825162",
    },
    // {
    //   name: "Cash App",
    //   url: "https://cash.app/$AmitTaggarse",
    // }
  ];

  return (
    <div className="relative p-0.5 rounded-2xl bg-gradient-to-r from-yellow-400 to-pink-500">
      <div className="bg-white p-6 rounded-2xl">
        <h3 className="text-2xl font-bold mb-6 text-center">Online:</h3>
        <div className="flex flex-col md:flex-row gap-4 items-stretch justify-center mb-8">
          {donationOptions.map((option, index) => (
            <a
              key={index}
              href={option.url}
              className="group relative flex-1 min-w-[200px]"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-yellow-400 to-pink-500 rounded-full transition-all duration-300 group-hover:opacity-90"></div>
              <div className="relative px-6 py-3 text-center text-white font-bold rounded-full">
                {option.name}
              </div>
            </a>
          ))}
        </div>
        <div className="text-center text-sm text-gray-600 mt-4 mb-2">
          {/* CashApp with memo "To BPP" with name, email of sender */}
        </div>
      </div>
    </div>
  );
};

export default OnlineDonationButtons;