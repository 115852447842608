import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const Gallery = () => {
  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 10);
      
      // Update current index based on scroll position
      const imageWidth = 600; // Width of each image container
      const gap = 24; // Gap between images (6 * 4px from gap-6)
      const newIndex = Math.round(scrollLeft / (imageWidth + gap));
      setCurrentIndex(newIndex);
    }
  };

  const scrollToImage = (index) => {
    if (scrollContainerRef.current) {
      const imageWidth = 600; // Width of each image container
      const gap = 24; // Gap between images (6 * 4px from gap-6)
      const container = scrollContainerRef.current;
      const containerWidth = container.clientWidth;
      const scrollPosition = (imageWidth + gap) * index;
      
      // Center the image by adjusting for container width
      const offset = (containerWidth - imageWidth) / 2;
      container.scrollTo({
        left: scrollPosition - offset,
        behavior: 'smooth'
      });
    }
  };

  const scroll = (direction) => {
    const newIndex = direction === 'left' ? 
      Math.max(0, currentIndex - 1) : 
      Math.min(9, currentIndex + 1);
    setCurrentIndex(newIndex);
    scrollToImage(newIndex);
  };

  // Ensure proper centering on resize
  useEffect(() => {
    const handleResize = () => {
      scrollToImage(currentIndex);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentIndex]);

  // Initial centering
  useEffect(() => {
    scrollToImage(0);
  }, []);

  return (
    <div className="mt-16 mb-16">
      <h2 className="text-4xl font-bold mb-8 text-center">Gallery</h2>
      <div className="gradient-line w-40 h-1 mx-auto mb-12 bg-gradient-to-r from-yellow-400 to-pink-500"></div>
      
      <div className="relative max-w-7xl mx-auto px-4">
        {/* Left Arrow */}
        {showLeftArrow && (
          <button 
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 rounded-full p-3 shadow-lg hover:bg-white transition-colors"
            aria-label="Previous images"
          >
            <ChevronLeft size={32} />
          </button>
        )}
        
        {/* Image Container */}
        <div 
          ref={scrollContainerRef}
          onScroll={handleScroll}
          className="flex overflow-x-auto gap-6 pb-6 scrollbar-hide scroll-smooth"
          style={{ 
            scrollbarWidth: 'none', 
            msOverflowStyle: 'none',
            scrollSnapType: 'x mandatory',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          {[...Array(10)].map((_, index) => (
            <div 
              key={index} 
              className="flex-none w-[600px] h-[400px] overflow-hidden rounded-lg bg-gray-100 shadow-lg"
              style={{ 
                scrollSnapAlign: 'center',
                scrollSnapStop: 'always'
              }}
            >
              <img
                src={`/img/M${index + 1}.jpeg`}
                alt={`Gallery image ${index + 1}`}
                className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
              />
            </div>
          ))}
        </div>
        
        {/* Right Arrow */}
        {showRightArrow && (
          <button 
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 rounded-full p-3 shadow-lg hover:bg-white transition-colors"
            aria-label="Next images"
          >
            <ChevronRight size={32} />
          </button>
        )}

        {/* Optional: Add dots indicator for current image */}
        <div className="flex justify-center gap-2 mt-4">
          {[...Array(10)].map((_, index) => (
            <button
              key={index}
              onClick={() => {
                setCurrentIndex(index);
                scrollToImage(index);
              }}
              className={`w-2 h-2 rounded-full transition-all ${
                currentIndex === index 
                  ? 'bg-yellow-400 w-4' 
                  : 'bg-gray-300'
              }`}
              aria-label={`Go to image ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;