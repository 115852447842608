import React from 'react';
import SharedLayout from './SharedLayout';
import { Link } from 'react-router-dom';
import SectionHeading from './SectionHeading';

const AthmedPage = () => {
  return (
    <SharedLayout>
      {/* Hero Section remains the same */}
      <section className="bg-cover bg-center h-[35vh]" style={{backgroundImage: 'url("/img/ATHMED header.jpg")'}}>
        <div className="h-full flex items-center justify-center text-white">
          <div className="text-center">
            <h1 
              className="text-[3rem] font-bold mb-4"
              style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}
            >
              ATHMED
            </h1>
          </div>
        </div>
      </section>

      <SectionHeading title="ATHMED Mentorship Program" />

      {/* Content Section */}
      <section className="py-16 px-6 sm:px-8 md:px-16 lg:px-24">
        <div className="max-w-4xl mx-auto">
          {/* Description */}
          <p className="text-lg text-center mb-16">
            The Advanced Therapies & Medical Education (ATHMED) Mentorship Program, led by Dr. Amit Taggarse, is designed to provide specialized medical care, training and guidance in advanced surgical therapies for heart & lung failure. This program focuses on areas such as bypass surgery for patients with low heart function and mechanical circulatory support. Participants at various levels of education and experience receive mentorship from experienced professionals, enhancing their clinical skills and knowledge in these specialized fields. From high schoolers wanting to become doctors to surgical residents ready to enter the community, anyone needing mentoring will benefit from this program.
          </p>

          {/* Image Grid */}
          <div className="space-y-8 mb-16">
            {/* First Row - Two Images with adjusted widths */}
            <div className="grid grid-cols-12 gap-8">
              {/* First Image - narrower */}
              <div className="col-span-12 md:col-span-5 rounded-lg overflow-hidden shadow-lg h-96">
                <img 
                  src="/img/A1.jpeg" 
                  alt="ATHMED Program" 
                  className="w-full h-full object-cover"
                />
              </div>

              {/* Second Image - broader */}
              <div className="col-span-12 md:col-span-7 rounded-lg overflow-hidden shadow-lg h-96">
                <img 
                  src="/img/A2.jpeg" 
                  alt="ATHMED Program Activities" 
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            {/* Second Row - Single Image */}
            <div className="max-w-4xl mx-auto">
              <div className="rounded-lg overflow-hidden shadow-lg h-96">
                <img 
                  src="/img/A3.png" 
                  alt="ATHMED Program Group" 
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          {/* Join Button */}
          <div className="text-center">
            <Link 
              to="/membership" 
              className="inline-block bg-[#EFBC40] text-black font-bold py-3 px-12 rounded-full hover:bg-opacity-90 transition duration-300"
            >
              Join our Community
            </Link>
          </div>
        </div>
      </section>
    </SharedLayout>
  );
};

export default AthmedPage;