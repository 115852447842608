import React from 'react';
import SharedLayout from './SharedLayout';
import './HomePage.css'; // Reuse HomePage styles
import { Link } from 'react-router-dom';
import SectionHeading from './SectionHeading';

const AboutPage = () => {
  return (
    <SharedLayout>
      {/* Hero Section */}
      <section className="bg-cover bg-center h-[35vh]" style={{backgroundImage: 'url("/img/about-hero.png")'}}>
        <div className="h-full flex items-center justify-center text-white">
          <div className="text-center">
            <h1 className="text-[3rem] font-bold mb-4" style={{ 
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' 
                }}>About</h1>
          </div>
        </div>
      </section>

      <SectionHeading title="Our Story" />

      {/* About Content */}
      <section className="py-16 px-6 sm:px-8 md:px-16 lg:px-24">
        <div className="max-w-4xl mx-auto">
          <p className="text-lg mb-16 text-center">
            Bloom Parks and Projects (BPP) began as a dream to create spaces where every child, no matter their
            background, can thrive. In 2021, Dr. Amit Taggarse dedicated land in Edinburg, Texas, inspired by his
            late parents' dedication to community service in India. Since then, BPP has expanded to include parks
            in Fort Smith, Arkansas, and Hyderabad, India—creating welcoming spaces for children and families to
            come together and flourish. Today, supported by a dedicated board and volunteers, Bloom Parks and
            Projects is building a brighter, more inclusive future across the USA and India.
          </p>

          <h2 className="text-2xl font-bold mb-4 text-center">Mission Statement</h2>
          <p className="text-lg mb-16 text-center">
            To nurture the future by ensuring every bud and every child has the opportunity to blossom.
          </p>

          <h2 className="text-2xl font-bold mb-4 text-center">Vision and Plan</h2>
          <p className="text-lg mb-16 text-center">
            To build a caring community focused on supporting all children, especially those who are
            underprivileged or have special needs, through mentorship, values-based education, and joyful events.
            Our three parks—located in Edinburg, Fort Smith, and Hyderabad—serve as vibrant spaces offering
            activities like petting zoos, cycling tracks, art rooms, and community gardens for children and families.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
  <div className="h-96 overflow-hidden rounded-lg">
    <img 
      src="/img/abtImg1.png" 
      alt="About image 1" 
      className="w-full h-full object-cover"
    />
  </div>
  <div className="h-96 overflow-hidden rounded-lg">
    <img 
      src="/img/abtImg2.png" 
      alt="About image 2" 
      className="w-full h-full object-cover"
    />
  </div>
  <div className="h-96 overflow-hidden rounded-lg">
    <img 
      src="/img/abtImg3.png" 
      alt="About image 3" 
      className="w-full h-full object-cover"
    />
  </div>
</div>

          <h2 className="text-2xl font-bold mb-4 text-center">Founder</h2>
          <p className="text-lg mb-16 text-center">
            Dr. Amit Taggarse, a cardiothoracic surgeon, founded Bloom Parks and Projects to honor the legacy of
            his late parents, Dr. Sudha and Dr. Kishore Taggarse, in supporting underprivileged communities.
            Inspired by his upbringing and medical career, he envisioned spaces beyond healthcare where children
            can learn, grow, and thrive—connecting the values of compassion and community in every park.
          </p>

          <h2 className="text-2xl font-bold mb-4 text-center">Our team and values</h2>
          <p className="text-lg mb-16 text-center">
            As the founder and director of Bloom Parks, Dr. Amit Taggarse leads the organization with a passion
            for creating inclusive spaces. He is supported by Ms. Rati Tripathi, Director of Mentorship Programs,
            who oversees initiatives that guide and support children in their growth. Together, they are backed by a
            dedicated board of volunteers who share a commitment to nurturing every child's potential.
          </p>

          <h2 className="text-2xl font-bold mb-4 text-center">Our Purpose</h2>
          <p className="text-lg mb-16 text-center">
            Bloom Parks and Projects stands out because it provides more than recreational spaces. Our parks
            serve as nurturing environments where children and families come together to experience connection,
            growth, and joy. With each activity and program, we focus on creating opportunities for children to
            build resilience, foster curiosity, and feel a sense of belonging.
          </p>

          <div className="text-center">
          <div className="text-center">
  <Link 
    to="/membership" 
    className="inline-block bg-[#EFBC40] text-black font-bold py-3 px-8 rounded-full hover:bg-opacity-90 transition duration-300"
  >
    Join our Community
  </Link>
</div>
          </div>
        </div>
      </section>
    </SharedLayout>
  );
};

export default AboutPage;