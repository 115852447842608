import React from 'react';

const ContactUsSection = () => {
  return (
    <section className="bg-white py-24 px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold mb-8 text-center">CONTACT US</h2>
        <div className="gradient-line w-40 h-1 mx-auto mb-16 bg-gradient-to-r from-yellow-400 to-pink-500"></div>
        <div className="flex flex-col md:flex-row items-center justify-between mb-16">
          <div className="text-3xl font-bold mb-12 md:mb-0 text-center md:text-left w-full md:w-auto">
            Let's<br />bloom<br />together!
          </div>
          <div className="flex flex-col items-center mb-12 md:mb-0">
            <img src="/img/email.png" alt="Email" className="w-16 h-16 mb-4" />
            <a href="mailto:hello@thebloomprojects.org" className="text-lg text-center">hello@thebloomprojects.org</a>
          </div>
          <div className="flex flex-col items-center">
            <img src="/img/postal.png" alt="Address" className="w-16 h-16 mb-4" />
            <p className="text-lg text-center">
              8612 S 31st Terrace Fort Smith<br />
              Arkansas 72908, USA.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;