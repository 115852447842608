import React from 'react';
import { ArrowRight, Menu, Heart } from 'lucide-react';
import { Link } from 'react-router-dom';
import './HomePage.css';
import WhyBloomSection from './WhyBloomSection.js';
import Navbar from './Navbar';
import EventsSection from './EventsSection';
import ContactUsSection from './ContactUsSection';
import SharedLayout from './SharedLayout';

const HomePage = () => {
  return (
    <SharedLayout showFooter={true}>
      <section className="hero-section bg-cover bg-center" 
               style={{backgroundImage: 'url("/img/homepage header.png")'}}>
        {/* Top row with logo and menu */}
        <div className="flex justify-between items-start p-6">
          <img src="/img/logo.png" alt="Bloom Parks and Projects Logo" className="w-24 h-24 sm:w-32 sm:h-32" />
        </div>

        {/* Hero content */}
        <div className="hero-content text-white">
          <div className="title-container">
            <h1 className="hero-title" style={{ 
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' 
                }}>Bloom Parks and Projects</h1>
            <p className="hero-subtitle">Every bud and every child can blossom!</p>
          </div>
        </div>

        {/* Button container */}
        <div className="button-container">
          <Link to="/membership" className="join-button">
            Join the Community
          </Link>
          <Link to="/donate" className="donate-button">
            <Heart size={24} className="inline-block mr-3 stroke-current fill-none" />
            Donate
          </Link>
        </div>
      </section>

      {/* Why Bloom Section - passing navigation props */}
      <WhyBloomSection 
        onLearnMoreClick={() => '/about'}
        onMembershipClick={() => '/membership'}
        onVolunteerClick={() => '/membership'}
        onDonateClick={() => '/donate'}
      />

      {/* Events Section */}
      <EventsSection />

      {/* Contact Section */}
      <ContactUsSection />

    </SharedLayout>
  );
};

export default HomePage;