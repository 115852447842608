import React from 'react';

const SectionHeading = ({ 
  title, 
  className = "",
  topSpacing = "pt-16", // Default top padding
  bottomSpacing = "mb-0" // Default bottom margin
}) => {
  return (
    <div className={`text-center ${topSpacing} ${bottomSpacing} ${className}`}>
      <h2 className="text-4xl font-bold mb-8">{title}</h2>
      <div className="gradient-line w-40 h-1 mx-auto bg-gradient-to-r from-yellow-400 to-pink-500"></div>
    </div>
  );
};

export default SectionHeading;