import React from 'react';
import SharedLayout from './SharedLayout';
import { Heart } from 'lucide-react';
import SectionHeading from './SectionHeading';
import OnlineDonationButtons from './OnlineDonationButtons';
import OtherDonationSections from './OtherDonationSection';
import { Link } from 'react-router-dom';


const DonatePage = () => {
  return (
    <SharedLayout>
      {/* Hero Section */}
      <section className="bg-cover bg-center h-[35vh]" style={{backgroundImage: 'url("/img/donate-hero.png")'}}>
        <div className="h-full flex items-center justify-center text-white">
          <div className="text-center">
            <h1 className="text-[3rem] font-bold mb-4" style={{ 
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' 
                }}>Donate</h1>
          </div>
        </div>
      </section>

      <SectionHeading title="Make an Impact" />


      {/* Donation Content */}
      <section className="py-16 px-6 sm:px-8 md:px-16 lg:px-24">
        <div className="max-w-4xl mx-auto">
          <p className="text-lg mb-16 text-center">
            Your support helps us create nurturing spaces where every child can grow and thrive. Donations to
            Bloom Parks and Projects allow us to maintain our parks, expand our programs, and provide
            meaningful experiences for children and families in need.
          </p>

          {/* Ways to Support */}
          <SectionHeading title="Ways to Support" topSpacing="pt-0" bottomSpacing="mb-10" />

          <div className="space-y-12 mb-16 text-center">
            <div>
              <h3 className="text-2xl font-bold mb-2">One-Time Donation:</h3>
              <p className="text-lg">Make a direct impact by supporting ongoing projects.</p>
            </div>
            
            <div>
              <h3 className="text-2xl font-bold mb-2">Monthly Giving:</h3>
              <p className="text-lg">Contribute monthly to sustain our parks and programs.</p>
            </div>
            
            <div>
              <h3 className="text-2xl font-bold mb-2">Sponsor a Program:</h3>
              <p className="text-lg">Choose a specific activity like the Petting Zoo or Art Center.</p>
            </div>
          </div>

          {/* Immediate Needs */}
        <SectionHeading title="Immediate Needs: Help Fund Our Growth" topSpacing="pt-0" bottomSpacing="mb-10" />
          <p className="text-lg mb-8 text-center">
            Your support will directly impact our current projects and help us continue creating safe spaces for
            children. Here's where your donations can make a difference:
          </p>

          <div className="space-y-4 mb-16 flex flex-col items-center">
            {[
              "Irrigation System: Ensure water for existing and future trees and animals.",
              "Animal Enclosures: Reinforce cages for rabbits, guinea pigs, and birds.",
              "Staff Office Construction: Build an office for on-site coordination.",
              "Animal Care Support: Hire part-time help for daily animal care."
            ].map((need, index) => (
              <div key={index} className="flex items-center space-x-4 justify-center w-full">
                <Heart className="text-pink-500 flex-shrink-0" size={24} />
                <p className="text-lg text-center">{need}</p>
              </div>
            ))}
          </div>

          {/* How to Donate */}
          <SectionHeading title="How to Donate" topSpacing="pt-0" bottomSpacing="mb-10" />
          <p className="text-sm text-center text-gray-600 mb-8">
    (BPP is a registered nonprofit 501c3 and a tax exemption certificate can be emailed to you{' '}
    <a 
      href="mailto:hello@thebloomprojects.org?subject=Tax%20Exemption%20Certificate%20Request" 
      className="text-pink-500 hover:text-pink-600 underline transition-colors duration-300"
    >
      on request
    </a>
    .)
  </p>
          <div className="space-y-8 mb-16 text-center">

              
              <OnlineDonationButtons />


            <OtherDonationSections />



          </div>

          <p className="text-xl font-bold text-center mb-2">
          We’re grateful for your generosity and support!
          </p>
          <p className="text-xl font-bold text-center mb-8">
            Every contribution, big or small, makes a lasting difference.
          </p>

          <div className="text-center">
  <Link 
    to="/membership" 
    className="inline-block bg-[#EFBC40] text-black font-bold py-3 px-8 rounded-full hover:bg-opacity-90 transition duration-300"
  >
    Join our Community
  </Link>
</div>
        </div>
      </section>
    </SharedLayout>
  );
};

export default DonatePage;