import React from 'react';
import SharedLayout from './SharedLayout';

const PrivacyPolicyPage = () => {
  return (
    <SharedLayout>
      {/* Hero Section */}
      <section className="bg-cover bg-center h-[35vh]" style={{backgroundImage: 'url("/img/homepage header.png")'}}>
        <div className="h-full flex items-center justify-center text-white">
          <div className="text-center">
            <h1 className="text-[3rem] font-bold mb-4" style={{ 
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' 
                }}>Privacy Policy</h1>
          </div>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-16 px-6 sm:px-8 md:px-16 lg:px-24">
        <div className="max-w-4xl mx-auto">
          <div className="mb-12">
            <p className="text-sm text-gray-600 mb-4">Effective Date: 22 September 2024</p>
            <p className="text-sm text-gray-600 mb-8">Last Updated: 16 October 2024</p>
            
            <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
            <p className="text-lg mb-8">
              At Bloom Parks and Projects (BPP) and SKTWS, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your personal data when you interact with us through our website, events, or other activities.
            </p>

            <h2 className="text-2xl font-bold mb-4">2. Information We Collect</h2>
            <h3 className="text-xl font-bold mb-3">2.1 Personal Information</h3>
            <ul className="list-disc pl-6 mb-6 text-lg space-y-2">
              <li>Contact Information: Name, email address, phone number, postal address.</li>
              <li>Demographic Information: Date of birth, gender, nationality, etc.</li>
              <li>Volunteer/Member Information: Skills, experience, volunteer preferences, certifications, emergency contact details.</li>
              <li>Payment Information: Credit card details, bank account numbers (for processing donations or membership fees).</li>
              <li>Health Information (if applicable): Allergies or special needs to ensure safety at events.</li>
            </ul>

            <h3 className="text-xl font-bold mb-3">2.2 Non-Personal Information</h3>
            <ul className="list-disc pl-6 mb-8 text-lg space-y-2">
              <li>Browser and Device Information: Information about the device you use to access our services, such as your IP address, browser type, and operating system.</li>
              <li>Usage Information: Details about your interaction with our website, such as the pages you visit, the links you click, and the duration of your visit.</li>
            </ul>

            <h2 className="text-2xl font-bold mb-4">3. How We Use Your Information</h2>
            <ul className="list-disc pl-6 mb-8 text-lg space-y-2">
              <li>To Process Donations and Membership Fees: To process your payments and issue tax-exemption certificates.</li>
              <li>To Manage Membership and Volunteers: To coordinate event participation, provide relevant training or safety information, and assign roles.</li>
              <li>To Improve Our Services: To analyze how visitors use our website and improve the user experience.</li>
              <li>To Send Communications: To send you newsletters, updates, and notifications about upcoming events and projects, as well as to respond to your inquiries.</li>
              <li>To Ensure Safety: To track health or emergency contact information where relevant to ensure the safety of participants in activities.</li>
            </ul>

            <h2 className="text-2xl font-bold mb-4">4. Sharing Your Information</h2>
            <p className="text-lg mb-4">We do not sell or trade your personal information. However, we may share your information with third parties under the following circumstances:</p>
            <ul className="list-disc pl-6 mb-8 text-lg space-y-2">
              <li>Service Providers: We may share information with trusted third parties who help us manage our website, process payments, or organize events.</li>
              <li>Legal Requirements: We may disclose your information if required by law, such as to comply with a subpoena or other legal process, or if we believe it is necessary to protect our rights or the safety of others.</li>
              <li>With Your Consent: We will share your information with other parties when you give us explicit permission to do so.</li>
            </ul>

            <h2 className="text-2xl font-bold mb-4">5. Data Security</h2>
            <p className="text-lg mb-8">
              We take appropriate technical and organizational measures to ensure the security of your personal information. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.
            </p>

            <h2 className="text-2xl font-bold mb-4">6. Retention of Information</h2>
            <p className="text-lg mb-8">
              We retain your personal information only for as long as necessary for the purposes outlined in this policy, or as required by law. If you would like us to delete your information, please contact us and we will comply with your request to the extent permissible by law.
            </p>

            <h2 className="text-2xl font-bold mb-4">7. Your Rights</h2>
            <p className="text-lg mb-4">You have the following rights regarding your personal information:</p>
            <ul className="list-disc pl-6 mb-8 text-lg space-y-2">
              <li>Access: You have the right to request a copy of the personal data we hold about you.</li>
              <li>Correction: You may request corrections to any personal data that is inaccurate or incomplete.</li>
              <li>Deletion: You may request that we delete your personal data, subject to certain legal obligations.</li>
              <li>Objection: You may object to the processing of your personal data in certain situations.</li>
              <li>Data Portability: You have the right to request that your personal data be transferred to another organization, where technically feasible.</li>
            </ul>

            <h2 className="text-2xl font-bold mb-4">8. Third-Party Links</h2>
            <p className="text-lg mb-8">
              Our website may contain links to third-party websites. We are not responsible for the privacy practices of these external sites. We encourage you to review the privacy policies of any third-party websites you visit.
            </p>

            <h2 className="text-2xl font-bold mb-4">9. Cookies and Tracking Technologies</h2>
            <p className="text-lg mb-8">
              We may use cookies or other tracking technologies to enhance your experience on our website. Cookies are small text files that are stored on your device to collect standard internet log information and visitor behavior information. You can manage your cookie preferences through your browser settings.
            </p>

            <h2 className="text-2xl font-bold mb-4">10. Opting Out and Unsubscribing</h2>
            <p className="text-lg mb-4">
              You have the right to opt out of receiving communications from us at any time. If you no longer wish to receive newsletters, updates, or any other non-essential communications, you can unsubscribe by:
            </p>
            <ul className="list-disc pl-6 mb-4 text-lg space-y-2">
              <li>Clicking the Unsubscribe Link: All of our email communications contain an unsubscribe link at the bottom. Simply click the link, and you will be directed to a page to confirm your request.</li>
              <li>Emailing Us Directly: Please refer to the contact details provided below, and email us with the subject line 'Unsubscribe'. We will promptly remove you from our mailing list.</li>
            </ul>
            <p className="text-lg mb-8">
              Please note that even if you unsubscribe from marketing or promotional communications, we may still need to send you administrative messages, such as event confirmations or important updates related to your membership, donations, or participation in activities.
            </p>

            <h2 className="text-2xl font-bold mb-4">11. Changes to This Policy</h2>
            <p className="text-lg mb-8">
              We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this policy periodically.
            </p>

            <h2 className="text-2xl font-bold mb-4">12. Contact Us</h2>
            <p className="text-lg mb-4">If you have any questions about this Privacy Policy or how we handle your personal information, please contact us at:</p>
            <ul className="list-none mb-8 text-lg space-y-2">
              <li>Email: hello@thebloomprojects.org</li>
              <li>Mailing Address: 8612 S 31st Terrace Fort Smith Arkansas 72908, USA.</li>
            </ul>
          </div>
        </div>
      </section>
    </SharedLayout>
  );
};

export default PrivacyPolicyPage;