import React from 'react';
import { Link } from 'react-router-dom';

const WhyBloomSection = () => {
  const cards = [
    {
      title: "Membership",
      description: "Grow with us—be part of a community that nurtures change.",
      buttonText: "Become a member",
      icon: "/img/membership.png",
      link: "/membership",
      buttonClass: "bg-[#EFBC40] text-black hover:bg-opacity-90"
    },
    {
      title: "Volunteer",
      description: "Join the fun! Explore, play, and grow with us.",
      buttonText: "Volunteer with Us",
      icon: "/img/volunteer.png",
      link: "/membership",
      buttonClass: "bg-[#EFBC40] text-black hover:bg-opacity-90"
    },
    {
      title: "Donate",
      description: "Help us nurture safe, joyful spaces for every child. Your support makes all the difference.",
      buttonText: "Make an impact",
      icon: "/img/donation.png",
      link: "/donate",
      buttonClass: "bg-[#EA328B] text-white hover:bg-opacity-90"
    }
  ];

  return (
    <section className="bg-gray-50 py-16 px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold mb-8 text-center">Why Bloom?</h2>
        <div className="w-40 h-1 mx-auto mb-8 bg-gradient-to-r from-yellow-400 to-pink-500"></div>
        
        <p className="text-lg mb-8 max-w-4xl mx-auto text-center">
          Bloom Parks and Projects was created with a vision to cultivate a space where every child,
          no matter their background or abilities, can thrive and blossom. We believe that a nurturing
          environment is the foundation for growth and positive change, not just for individuals but for
          entire communities.
        </p>

        <Link to="/about" className="block w-fit mx-auto mb-16">
          <button className="bg-gradient-to-r from-[#EFBC40] to-[#EA328B] text-white font-bold py-3 px-8 rounded-full hover:opacity-90 transition duration-300">
            Learn more
          </button>
        </Link>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {cards.map((card, index) => (
            <div key={index} className="relative p-0.5 rounded-lg bg-gradient-to-r from-yellow-400 to-pink-500">
              <div className="bg-white p-8 rounded-lg h-full flex flex-col items-center">
                <h3 className="text-2xl font-bold mb-4">{card.title}</h3>
                <img src={card.icon} alt={`${card.title} icon`} className="w-24 h-24 mb-4" />
                <p className="text-center mb-6">{card.description}</p>
                <Link to={card.link} className="mt-auto">
                  <button className={`font-bold py-3 px-8 rounded-full transition duration-300 ${card.buttonClass}`}>
                    {card.buttonText}
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyBloomSection;